export default {
  msg91: {
    API_KEY: "317001AmlRKbWO5TJz5e3bf5bcP1",
    templateId: "6177cfd0f6ea5d729c0da0c5",
    baseUrl: "https://prod.entitled.co.in",
    otpUrl: "https://prod.entitled.co.in/otpservice",
  },
  api: "https://prod.entitled.co.in",
  apiConsultation: "https://prod.entitled.co.in/whatsapp",
  apinomination: "https://prod.entitled.co.in/pfNomination",
};
