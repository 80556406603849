import cn from "classnames";
import { findInputError, isFormInvalid } from "../utils";
import { useFormContext } from "react-hook-form";

const input_tailwind = `rounded-md appearance-none relative block w-full h-12 px-3 py-2 border border-gray-300 placeholder-gray-500 
  text-gray-900 focus:outline-none focus:ring-[#5A786F] focus:border-[#5A786F] focus:z-10 sm:text-sm`;

const counter_input =
  "bg-[#fff] text-3xl text-[#4D4D4D] text-center font-sans h-12 w-24 mx-1 cursor-pointer focus:ring-[#5A786F] focus:border-[#5A786F] border border-[#D9D9D9] rounded-md";

const select_tailwind = `rounded-md relative block w-full h-12 px-3 py-2 border border-gray-300 placeholder-gray-500 
text-gray-900 focus:outline-none focus:ring-[#5A786F] focus:border-[#5A786F] focus:z-10 sm:text-sm`;

export default function Input({
  name,
  label,
  type,
  id,
  placeholder,
  validation,
  multiline,
  className,
  inputInfoText,
  text,
  options,
  setValue,
}) {
  const {
    register,
    getValues,
    formState: { errors },
  } = useFormContext();

  const inputErrors = findInputError(errors, name);
  const isInvalid = isFormInvalid(inputErrors);

  const renderInput = () => {
    switch (true) {
      case name === "loan_period" && type === "number":
        return (
          <>
            <div className="flex flex-row">
              <button
                data-action="decrement"
                onClick={() => {
                  const val = Number(getValues()[name] || 0);
                  setValue(name, val > 0 ? val - 1 : val, {
                    shouldDirty: true,
                    shouldValidate: true,
                  });
                }}
                className="bg-[#fff] text-[#4D4D4D] text-base font-sans h-12 w-12 cursor-pointer border border-[#D9D9D9] rounded-md"
              >
                <span style={{ fontSize: "32px" }}>-</span>
              </button>
              <input
                id={id}
                type={type}
                className={cn(
                  counter_input,
                  isInvalid ? "border-[#A82424] focus:border-[#A82424]" : ""
                )}
                placeholder={placeholder}
                {...register(name, validation)}
              ></input>
              <button
                data-action="increment"
                onClick={() =>
                  setValue(name, Number(getValues()[name] || 0) + 1, {
                    shouldDirty: true,
                    shouldValidate: true,
                  })
                }
                className="bg-[#fff] text-[#4D4D4D] text-base font-sans h-12 w-12 cursor-pointer border border-[#D9D9D9] rounded-md"
              >
                <span style={{ fontSize: "32px" }}>+</span>
              </button>
            </div>
            {inputInfoText && !isInvalid && (
              <span className="text-sm text-[#4D4D4D] font-sans">
                {inputInfoText}
              </span>
            )}
            {isInvalid && (
              <span className="text-sm text-[#A82424] font-sans">
                {inputErrors?.error?.message}
              </span>
            )}
          </>
        );
      case type === "number" || type === "text" || type === "date":
        return (
          <>
            <input
              id={id}
              type={type}
              className={cn(
                input_tailwind,
                isInvalid ? "border-[#A82424] focus:border-[#A82424]" : ""
              )}
              placeholder={placeholder}
              {...register(name, validation)}
            />
            {inputInfoText && !isInvalid && (
              <span className="text-sm text-[#4D4D4D] font-sans">
                {inputInfoText}
              </span>
            )}
            {isInvalid && (
              <span className="text-sm text-[#A82424] font-sans">
                {inputErrors?.error?.message}
              </span>
            )}
          </>
        );
      case name === "terms_conditions" && type === "textarea":
        return (
          <div
            className="text-base text-[#4D4D4D] font-sans text-left h-[40vh] overflow-y-auto pr-2"
            dangerouslySetInnerHTML={{
              __html: text,
            }}
          />
        );
      case type === "select":
        return (
          <>
            <select
              id={id}
              className={cn(select_tailwind)}
              {...register(name, validation)}
            >
              {placeholder && <option value={""}>{placeholder}</option>}
              {options?.map((el, index) => (
                <option value={`${el?.value}`} key={`${el?.value}-${index}`}>
                  {el?.label}
                </option>
              ))}
            </select>
          </>
        );
      default:
        return "";
    }
  };

  return (
    <div className={cn("flex flex-col w-full my-4", className)}>
      <div>
        <label
          htmlFor={id}
          className={cn(
            "text-base text-[#4D4D4D] font-sans",
            isInvalid ? "text-[#A82424]" : ""
          )}
        >
          {label}
        </label>
      </div>
      {renderInput()}
    </div>
  );
}
