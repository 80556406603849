const MobileNumberAlreadyRegistered = ({
  description,
  message,
  goToHomeScreen,
}) => {
  return (
    <>
      <div className="text-2xl text-[#4D4D4D] font-sans font-bold my-7 px-5">
        {message}
      </div>
      <div className="text-sm text-[#4D4D4D] font-sans my-7 mx-5">
        {description}
      </div>
      <div className="my-10 mx-5 flex items-center justify-center">
        <button className="text-[#4D4D4D] border border-[#4D4D4D] text-base py-2 px-3 rounded disabled:opacity-50">
          Contact customer support
        </button>
      </div>
      <div className="my-10 mx-5 flex items-center justify-center">
        <span
          className="text-base text-[#5A786F] font-sans border-[#5A786F] underline cursor-pointer"
          onClick={goToHomeScreen}
        >
          Go to homepage
        </span>
      </div>
    </>
  );
};
export default MobileNumberAlreadyRegistered;
