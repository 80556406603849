import axios from "axios";
import Credentials from "../config";

const axiosClient = axios.create({
  baseURL: Credentials.msg91.baseUrl,
});
axiosClient.defaults.headers = {
  "Content-Type": "application/json;charset=UTF-8",
  Accept: "application/json",
  "x-api-key": "Vz0xwOdli96i9KmebyP1o3IDnQBqiLOZ8iHynCzi",
};
axiosClient.defaults.timeout = 20000;

export function getRequest(URL) {
  return axiosClient.get(`${URL}`).then((response) => response);
}

export function postRequest(URL, payload) {
  return axiosClient.post(`${URL}`, payload).then((response) => response);
}

export function patchRequest(URL, payload) {
  return axiosClient.patch(`${URL}`, payload).then((response) => response);
}

export function deleteRequest(URL) {
  return axiosClient.delete(`${URL}`).then((response) => response);
}
