import { useState } from "react";
import { useLocation } from "react-router-dom";
import { Stepper, Step } from "react-form-stepper";
import { toast } from "react-toastify";
import { formFields } from "../constants/formFields";
import Header from "../components/Header";
import MobileVerification from "../components/MobileVerification";
import PersonalDetails from "../components/PersonalDetails";
import LoanDetails from "../components/LoanDetails";
import MobileNumberAlreadyRegistered from "../components/MobileNumberAlreadyRegistered";
import Terms from "../components/Terms";
import SuccessPage from "../components/SuccessPage";
import Loader from "../components/Loader";
import step_active from "../assets/icons/step_active.png";
import step_completed from "../assets/icons/step_completed.png";
import step_inactive from "../assets/icons/step_inactive.png";
import {
  loanFormCheckStatusApiCallHandler,
  sendLoanRequest,
} from "../service/loanService";
import "../style.css";
import { useEffect } from "react";

export default function LoanPage() {
  const [isLoading, setLoader] = useState(false);
  const [isMounted, setMounted] = useState(false);
  const [activeStep, setActiveStep] = useState(0);
  const [loanData, setLoanData] = useState(null);
  const [applicationId, setApplicationId] = useState("");
  const [verifyPhoneData, setVerifyPhoneData] = useState(null);
  const search = useLocation().search;
  const company = new URLSearchParams(search).get("partner") || "";
  const emp_id = new URLSearchParams(search).get("emp_id") || "";
  let fieldsState = {};
  if (formFields && company) {
    formFields[company]?.forEach((field) => (fieldsState[field.name] = ""));
  }

  useEffect(() => {
    const _data = JSON.parse(localStorage.getItem(`${company}-${emp_id}`));
    if (
      _data?.activeStep !== "" &&
      _data?.activeStep !== undefined &&
      _data?.activeStep !== null
    ) {
      setActiveStep(Number(_data?.activeStep));
    }
    if (_data?.loanData) {
      setLoanData(_data?.loanData);
    }
    if((_data?.activeStep !== "" || _data?.activeStep !== undefined || _data?.activeStep !== null) && company == "chaloNetwork"){
      setActiveStep(1);
    }

    setMounted(true);
  }, []);

  useEffect(() => {
    if (isMounted) {
      localStorage.setItem(
        `${company}-${emp_id}`,
        JSON.stringify({ activeStep: activeStep, loanData: loanData })
      );
    }
  }, [activeStep, loanData, company, emp_id]);

  const redirectToPersonalDetailPage = async (_data) => {
    try {
      const _request = {
        mobile_number: `${_data?.mobile_number}`,
        whatsapp_number: `${_data?.whatsapp_number}`,
        company_employee_id: emp_id || "OPTIONAL",
        company: company || "",
        url:window.location.href
      };
      setLoader(true);
      const result = await loanFormCheckStatusApiCallHandler(_request);
      const { status, description, message, employee } = result;
      if (status === "success") {
        if (description && message) {
          setVerifyPhoneData({ ...result });
        } else {
          setLoanData({
            ...loanData,
            ..._data,
            ...employee,
          });
          setActiveStep(1);
        }
      }
    } catch (err) {
      toast.error("Something went wrong!");
    } finally {
      setLoader(false);
    }
  };

  const redirectToLoanDetailPage = (_personalDetails) => {
    setLoanData({
      ...loanData,
      ..._personalDetails,
    });
    setActiveStep(2);
  };

  const redirectToTermsDetailPage = (_loanData) => {
    setLoanData({
      ...loanData,
      ..._loanData,
    });
    setActiveStep(3);
  };

  const saveLoanDetails = async () => {
    try {
      let data = { ...loanData };
      if (company !== "entitled" && company !== "enableCap") {
        data["company_name"] = company;
      }
      if (company === "entitled" || company === "EverestFleet") {
        data["common_form"] = true;
      } else {
        data["common_form"] = false;
      }
      if (company === "quess") {
        data["company_employee_id"] = emp_id;
      }
      delete data.otp;
      setLoader(true);
      const result = await sendLoanRequest(data);
      const { status, id } = result;
      if (status === "success") {
        window.gtag("event", "submission_success", { employee_id: emp_id });
        setApplicationId(id);
        localStorage.removeItem(`${company}-${emp_id}`);
      } else {
        window.gtag("event", "submission_error", { employee_id: emp_id });
      }
    } catch (err) {
      toast.error("Something went wrong!");
    } finally {
      setLoader(false);
    }
  };

  const goToHomeScreen = () => {
    setVerifyPhoneData(null);
    setLoanData(null);
  };

  const renderLoadForm = () => {
    switch (activeStep) {
      case 0:
        return (
          <>
            {verifyPhoneData ? (
              <MobileNumberAlreadyRegistered
                {...verifyPhoneData}
                goToHomeScreen={goToHomeScreen}
              />
            ) : (
              <MobileVerification
                company={company}
                redirectToStep={redirectToPersonalDetailPage}
              />
            )}
          </>
        );
      case 1:
        return (
          <PersonalDetails
            company={company}
            formFields={formFields}
            loanData={loanData}
            redirectToStep={redirectToLoanDetailPage}
          />
        );
      case 2:
        return (
          <LoanDetails
            company={company}
            formFields={formFields}
            redirectToStep={redirectToTermsDetailPage}
          />
        );
      case 3:
        return (
          <Terms
            company={company}
            formFields={formFields}
            redirectToStep={saveLoanDetails}
          />
        );
      default:
        break;
    }
  };
  const getStepImg = (_fromStep) => {
    if (activeStep === _fromStep) {
      return step_active;
    } else if (activeStep > _fromStep) {
      return step_completed;
    } else if (activeStep < _fromStep) {
      return step_inactive;
    }
  };

  return (
    <>
      {isLoading && <Loader />}
      <Header />
      {!applicationId ? (
        <>
          <Stepper activeStep={activeStep} className="!p-0 loan-stepper my-3">
            <Step
              label="Step 1"
              className="custom-step"
              style={{
                backgroundImage: `URL(${getStepImg(0)})`,
                backgroundSize: "cover",
                backgroundColor: "unset",
              }}
            />
            <Step
              label="Step 2"
              className="custom-step"
              style={{
                backgroundImage: `URL(${getStepImg(1)})`,
                backgroundSize: "cover",
                backgroundColor: "unset",
              }}
            />
            <Step
              label="Step 3"
              className="custom-step"
              style={{
                backgroundImage: `URL(${getStepImg(2)})`,
                backgroundSize: "cover",
                backgroundColor: "unset",
              }}
            />
            <Step
              label="Step 4"
              className="custom-step"
              style={{
                backgroundImage: `URL(${getStepImg(3)})`,
                backgroundSize: "cover",
                backgroundColor: "unset",
              }}
            />
          </Stepper>
          {renderLoadForm()}
        </>
      ) : (
        <SuccessPage applicationId={applicationId} company={company} />
      )}
    </>
  );
}
