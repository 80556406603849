import React from "react";
import { useEffect } from "react";
import axios from "axios";
import { useLocation } from "react-router-dom";
import Header from "../components/Header";
import "../style.css";

export default function Bot() {
  const search = useLocation().search;
  let age = new URLSearchParams(search).get("age") || "";
  let name = new URLSearchParams(search).get("name") || "";
  let gender = new URLSearchParams(search).get("gender") || "";
  let mobile = new URLSearchParams(search).get("phone_number") || "";
  let emp_id = new URLSearchParams(search).get("emp_id") || "";
  let flow = new URLSearchParams(search).get("flow") || "visit";
  let hospicash = new URLSearchParams(search).get("hospicash") || false;
  let company = new URLSearchParams(search).get("company") || " ";
  let channel = new URLSearchParams(search).get("channel") || " ";

  useEffect(() => {
    (async () => {
      window.gtag("event", "bot_redirection", {
        age: age,
        name: name,
        gender: gender,
        mobile: mobile,
        emp_id: emp_id,
        flow: flow,
      });
      const result = await axios.get(
        `https://prod.entitled.co.in/whatsapp?company=${company}&channel=${channel}&name=${name}&age=${
          age || 0
        }&gender=${gender}&mobile=${mobile}&flow=${flow}&emp_id=${emp_id}&hospicash=${hospicash}`
      );
      return result["data"];
    })();
  }, []);

  return (
    <>
      <Header hidePartnerLogo={true} />
      <div className="text-xl text-[#4D4D4D] font-sans font-bold my-7 px-5">
        Thank you for submitting your Request!.
      </div>
    </>
  );
}
